import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./page.css";
import "./main.css";
import axios from "axios";

const Blog = () => {
  const [hoveredImageId, setHoveredImageId] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

   const API_URL = "https://notification.metamindful.ai/api/blogs"; // Replace with your actual API URL
 // const API_URL = "http://localhost:3001/api/blogs"; // Replace with your actual API URL
  const IMAGE_URL = "https://notification.metamindful.ai/api/"; // Replace with your actual API URL

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(API_URL, {
          params: { isPublished: true }
        });
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  useEffect(() => {
    const circle = document.createElement("div");
    circle.classList.add("mouse-circle");
    document.body.appendChild(circle);

    const moveCircle = (event) => {
      circle.style.left = `${event.pageX}px`;
      circle.style.top = `${event.pageY}px`;

      if (hoveredImageId) {
        circle.classList.add("expanded");
      } else {
        circle.classList.remove("expanded");
      }
    };

    document.addEventListener("mousemove", moveCircle);

    return () => {
      document.removeEventListener("mousemove", moveCircle);
      document.body.removeChild(circle);
    };
  }, [hoveredImageId]);

  if (isLoading) {
    return (
        <div className="blog-page">
          <section className="section section-masthead text-center">
            <div className="container">
              <h1 className="xl section-masthead__heading d-inline-block my-0">Blog</h1>
            </div>
          </section>
          <div className="loading">Loading...
          </div>
        </div>
    );
  }

  return (
      <div className="blog-page">
        <section className="section section-masthead text-center">
          <div className="container">
            <h1 className="xl section-masthead__heading d-inline-block my-0">Blog</h1>
          </div>
        </section>
        <div className="blog-list">
          {blogs.map((post) => (
              <div className="blog-post" key={post._id}>
                <div className="blog-post__content">
                  <article className="post figure-post">
                    <div className="figure-post__media">
                      <Link
                          className="figure-post__media-link"
                          to={`/blog/${post._id}`}
                          onMouseEnter={() => setHoveredImageId(post._id)}
                          onMouseLeave={() => setHoveredImageId(null)}
                      >
                        <div className="lazy-wrapper">
                          <div className="lazy">
                            {post.image && (
                                <img
                                    src={`${IMAGE_URL}${post.image}`}
                                    width="1920"
                                    height="1280"
                                    alt={post.title}
                                />
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="figure-post__wrapper-info mt-3 pt-2">
                      <ul className="post-meta">
                        <li>
                          <a href="#">
                            <i className="material-icons">event</i>
                            <span>{new Date(post.createdAt).toDateString()}</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="material-icons">comment</i>
                            <span>3 Comments</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="figure-post__header mt-1">
                      <h2 className="h3">
                        <Link
                            to={`/blog/${post._id}`}
                            onMouseEnter={() => setHoveredImageId(post._id)}
                            onMouseLeave={() => setHoveredImageId(null)}
                        >
                          {post.title}
                        </Link>
                      </h2>
                      <div className="figure-post__content mt-1">
                        <p>{post.shortDescription}</p>
                      </div>
                    </div>
                    <div className="figure-post__wrapper-readmore mt-4">
                      <Link
                          to={`/blog/${post._id}`}
                          onMouseEnter={() => setHoveredImageId(post._id)}
                          onMouseLeave={() => setHoveredImageId(null)}
                          className="button button_icon button_bordered bg-gray-2"
                      >
                    <span className="button__label button__label-normal">
                      <span className="button__title">Read More</span>
                      <span className="button__icon button__icon_after knz-arrow-right"></span>
                    </span>
                        <span className="button__label button__label-hover">
                      <span className="button__title">Read More</span>
                      <span className="button__icon button__icon_after knz-arrow-right"></span>
                    </span>
                      </Link>
                    </div>
                  </article>
                </div>
              </div>
          ))}
        </div>
      </div>
  );
};

export default Blog;
