import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import "./page.css";
import "./main.css";
import { Form, Button, Row, Col } from 'react-bootstrap';

const BlogDetail = () => {
  const location = useLocation();
  const [blog, setBlog] = useState(null);
  const [comments, setComments] = useState([]);
  const [error, setError] = useState(null);
  const [commentError, setCommentError] = useState(null);
  const [commentSuccess, setCommentSuccess] = useState("");
  const [isPosting, setIsPosting] = useState(false);

  const [newComment, setNewComment] = useState({
    userName: "",
    userEmail: "",
    comment: "",
  });

  // For local development
  const API_URL = "https://notification.metamindful.ai/api/blogs";
  const COMMENTS_API_URL = "https://notification.metamindful.ai/api/comments";
  const IMAGE_URL = "https://notification.metamindful.ai/api/";

  // Extract the blog ID from the URL
  const extractIdFromUrl = () => {
    const match = location.pathname.match(/\/blog\/([^/]+)/);
    return match ? match[1] : null;
  };

  const id = extractIdFromUrl();

  // Fetch blog details
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${API_URL}/${id}`);
        setBlog(response.data);
      } catch (err) {
        setError("Error fetching blog data.");
        console.error("Error fetching blog:", err);
      }
    };

    if (id) {
      fetchBlog();
    }
  }, [id]);

  // Fetch comments for this blog and filter only published ones
  useEffect(() => {
    const fetchComments = async () => {
      try {
        // Change isPublished query param as needed. Here we are fetching unpublished for testing.
        const response = await axios.get(
            `${COMMENTS_API_URL}?blog=${id}&isPublished=true`
        );
        setComments(response.data);
      } catch (err) {
        console.error("Error fetching comments:", err);
      }
    };

    if (id) {
      fetchComments();
    }
  }, [id]);

  // Mouse effect (for additional UI flair)
  useEffect(() => {
    const circle = document.createElement("div");
    circle.classList.add("mouse-circle");
    document.body.appendChild(circle);

    const moveCircle = (event) => {
      circle.style.left = `${event.pageX}px`;
      circle.style.top = `${event.pageY}px`;
    };

    document.addEventListener("mousemove", moveCircle);

    return () => {
      document.removeEventListener("mousemove", moveCircle);
      document.body.removeChild(circle);
    };
  }, []);

  // Handler for form field changes
  const handleInputChange = (e) => {
    setNewComment({
      ...newComment,
      [e.target.name]: e.target.value,
    });
  };

  // Handler for comment submission
  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    setIsPosting(true);

    setCommentError(null);
    setCommentSuccess("");
    // Basic validation
    if (!newComment.userEmail || !newComment.comment|| !newComment.userName) {
      setCommentError("All Fields are required.");
      setIsPosting(false);
      return;
    }
    try {
      // The comment object must include the blog id
      const commentData = { ...newComment, blog: id };
      const response = await axios.post(COMMENTS_API_URL, commentData);

      // If the comment is published immediately, add it to the list.
      // Otherwise, just show the success message.
      if (response.data.isPublished) {
        setComments([response.data, ...comments]);
      }
      setCommentSuccess(
          "Your comment has been posted and will be published in a while."
      );
      // Reset form fields
      setNewComment({ userName: "", userEmail: "", comment: "" });

      // Clear success message after 3 seconds
      setTimeout(() => {
        setCommentSuccess("");
      }, 3000);
    } catch (err) {
      console.error("Error posting comment:", err);
      setCommentError("Error posting comment.");
    }finally {
      setIsPosting(false);
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!blog) {
    return <div style={{minHeight:'500px'}}>
      <h1 className="xl section-masthead__heading d-inline-block my-0 arts-split-text js-arts-split-text js-transition-heading"
          >Loading ...</h1>
    </div>;
  }

  const { title, longDescription, image, createdAt } = blog;

  return (
      <>
        <div className="container">

        <div className="section-masthead__subheading mb-2 arts-split-text js-arts-split-text"
             data-arts-split-text="lines, words" data-arts-split-text-set="words"
             data-arts-split-text-overflow-wrap="lines">
          <ul className="post-meta">
            <li><a href="#"><i className="material-icons">event</i><span>{new Date(createdAt).toDateString()}</span></a></li>

            <li><a href="#"><i className="material-icons">comment</i><span>{comments.length} Comment{comments.length>1 && 's'}</span></a></li>
          </ul>
        </div>
          <h1 className="xl section-masthead__heading d-inline-block my-0 arts-split-text js-arts-split-text js-transition-heading"
              data-arts-split-text="lines, chars" data-arts-split-text-set="chars"
              data-arts-split-text-overflow-wrap="lines" style={{fontSize:'48px'}}>{title}</h1>
          {image &&
              <div className="section section_h-900 mt-medium js-transition-img js-arts-parallax overflow mask-reveal"
                   style={{marginTop:'20px'}}
                   data-arts-parallax-factor-y="0.15">
                <div className="mask-reveal__layer mask-reveal__layer-1">
                  <div className="mask-reveal__layer mask-reveal__layer-2">
                    <div className="js-arts-parallax__scrub js-transition-img__transformed-el h-100"><img
                        className="of-cover" src={`${IMAGE_URL}${image}`} width="1920" height="1280"
                        alt="alt"/></div>
                  </div>
                </div>
              </div>
          }
        </div>


        <main className="page-wrapper__content">

          <section className="section section-blog section-content pt-medium pb-large" style={{paddingTop:'0px'}}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <article className="post" style={{textAlign:'left'}}>
                    <div className="post__content clearfix" dangerouslySetInnerHTML={{ __html: longDescription }}>

                    </div>
                    <div className="post__comments mt-small">
                      <div className="comments-area" id="comments">
                        <h4 className="comments-title">{comments.length} Comment{comments.length>1 && 's'}</h4>
                        <ol className="comment-list">
                              {comments.map((cmt) => (
                          <li className="comment">
                            <article className="comment-body">

                              <div className="comment-content">
                                <footer className="comment-meta">
                                  <b className="fn"><a href="#">{cmt.userName}</a></b>

                                  <div className="comment-metadata"><a href="#">
                                    <time dateTime="2013-03-14T07:57:01+00:00">{new Date(cmt.createdAt).toLocaleDateString()}</time>
                                  </a></div>
                                </footer>
                                <p>{cmt.comment}</p>

                              </div>
                            </article>
                          </li>
                                ))}




                        </ol>
                      </div>
                      <div className="comment-respond" id="respond">

                        <h4 className="comment-reply-title">Post a Comment</h4>
                        {commentError && <p className="error">{commentError}</p>}
                        {commentSuccess && <p className="success">{commentSuccess}</p>}
                        <form className="comment-form" onSubmit={handleCommentSubmit}>
                          <Row className="mb-3">
                            <Col md={6}>
                              <Form.Group controlId="userName">
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="userName"
                                    required
                                    placeholder="Your name"
                                    value={newComment.userName}
                                    onChange={handleInputChange}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group controlId="userEmail">
                                <Form.Label>Email:</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="userEmail"
                                    placeholder="Your email"
                                    value={newComment.userEmail}
                                    onChange={handleInputChange}
                                    required
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <Form.Group controlId="comment">
                                <Form.Label>Comment:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="comment"
                                    placeholder="Your comment"
                                    value={newComment.comment}
                                    onChange={handleInputChange}
                                    rows={4}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="text-end">
                            <Col>
                              <Button variant="dark" type="submit">
                                {isPosting ? "Posting..." : "Post Comment"}
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </div>


                  </article>
                </div>
              </div>
            </div>
          </section>

        </main>

      </>
  );
};

export default BlogDetail;
